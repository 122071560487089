import React, { useState, createContext } from 'react';

export const MainPageContext = createContext();

const MainPageContextProvider = ({ children }) => {
  const [showIntro, setShowIntro] = useState(true);
  return (
    <MainPageContext.Provider value={{ showIntro, setShowIntro }}>
      {children}
    </MainPageContext.Provider>
  );
};

export default MainPageContextProvider;
