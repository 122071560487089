/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';

import MainPageContextProvider from './src/context/MainPageContextProvider';

export const wrapRootElement = ({ element }) => (
  <MainPageContextProvider>{element}</MainPageContextProvider>
);
